<template>
  <ProductLink
    :product="product"
    :tab="'product_reviews'"
    class="product-rating"
    :class="{ simpleView }"
  >
    <NuxtRating
      :rating-value="product.getReviewsAverageRating()"
      :rating-size="ratingSize"
      active-color="#fbbd09"
    />
    <span
      v-if="isShowAverage && 0 !== product.getReviewsAverageRating()"
      class="product-rating__average"
    >
      {{ product.getFixedReviewsAverageRating(1) }}
    </span>
    <div class="product-rating__reviews">
      <template v-if="simpleView">
        <img
          class="product-rating__reviews-icon"
          src="/img/comment-icon.svg"
          alt="reviews count"
          loading="lazy"
          :style="{ '--review-icon-size': ratingSize }"
        />
        <span>{{ product.reviews.count }}</span>
      </template>
      <span v-else>
        {{ pluralFormatter(product.reviews.count, getTranslatedWords()) }}
      </span>
    </div>
  </ProductLink>
</template>

<script setup>
import { Product } from "~/models/product.model";

const props = defineProps({
  product: { type: Product, required: true },
  ratingSize: { type: Number, required: false, default: 24 },
  simpleView: { type: Boolean, required: false, default: false },
  isShowAverage: { type: Boolean, required: false, default: false },
});

const words = [
  {
    ru: "отзыв",
    uk: "відгук",
  },
  {
    ru: "отзыва",
    uk: "відгуки",
  },
  {
    ru: "отзывов",
    uk: "відгуків",
  },
];

const getTranslatedWords = () => words.map((word) => getLocalizedName(word));
</script>

<style scoped lang="scss">
.product-rating {
  --review-icon-size: 24;
  max-width: max-content;

  @include flex-container(row, flex-start, center);
  gap: 8px;

  &.simpleView {
    padding-left: 16px;

    @include mobile {
      padding-left: 8px;
    }

    & .product-rating__reviews {
      @include flex-container(row, flex-start, center);
      gap: 4px;

      @include font(12, 16);
      letter-spacing: 0.02em;
      color: var(--color-sky-darker);

      transition: color 0.2s;

      @include mobile {
        @include font(10, 14);
      }
    }
  }

  &__average {
    @include font(18, 22, 700);
    color: var(--color-rating);

    @include mobile {
      @include font(16, 20, 700);
    }
  }

  &__reviews {
    @include font(16, 20);
    text-decoration: underline;

    transition: color 0.2s;

    @include mobile {
      @include font(14, 18);
    }

    &:hover {
      color: var(--color-primary-base);
    }
  }

  &__reviews-icon {
    @include fixedHW(calc(var(--review-icon-size) * 1px));
  }
}
</style>
